.L8lE7q_error {
  color: red;
  margin: 1vh 0;
}

.L8lE7q_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.L8lE7q_contentContainer > div {
  width: 350px;
}

.L8lE7q_contentContainer .L8lE7q_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.L8lE7q_contentContainer .L8lE7q_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.L8lE7q_contentContainer .L8lE7q_pulsingText {
  animation: L8lE7q_pulse 1.5s linear infinite;
}

.L8lE7q_contentContainer .L8lE7q_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.L8lE7q_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.L8lE7q_downloadLinkDiv {
  margin: 2vh 0 0;
}

.L8lE7q_downloadLinkDiv .L8lE7q_downloadLink {
  color: #333;
}

.L8lE7q_downloadLinkDiv .L8lE7q_downloadLink:hover {
  opacity: .5;
}

@keyframes L8lE7q_pulse {
  50% {
    opacity: 0;
  }
}

.L8lE7q_header {
  width: 350px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 2vh;
  display: flex;
  position: relative;
}

.L8lE7q_header .L8lE7q_headerImage {
  width: 350px;
}

.L8lE7q_header .L8lE7q_robotImage {
  width: 350px;
  width: 348px;
  border: .5px solid #000;
  position: relative;
  top: -1px;
}

.L8lE7q_header .L8lE7q_swoosh {
  position: absolute;
  top: 30%;
  right: 3.8%;
}

.L8lE7q_header .L8lE7q_spinningSwoosh {
  animation: L8lE7q_spin 3s linear infinite;
  position: absolute;
  top: 30%;
  right: 3.8%;
}

@keyframes L8lE7q_spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.AUy_zG_error {
  color: red;
  margin: 1vh 0;
}

.AUy_zG_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.AUy_zG_contentContainer > div {
  width: 350px;
}

.AUy_zG_contentContainer .AUy_zG_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.AUy_zG_contentContainer .AUy_zG_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.AUy_zG_contentContainer .AUy_zG_pulsingText {
  animation: AUy_zG_pulse 1.5s linear infinite;
}

.AUy_zG_contentContainer .AUy_zG_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.AUy_zG_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.AUy_zG_downloadLinkDiv {
  margin: 2vh 0 0;
}

.AUy_zG_downloadLinkDiv .AUy_zG_downloadLink {
  color: #333;
}

.AUy_zG_downloadLinkDiv .AUy_zG_downloadLink:hover {
  opacity: .5;
}

@keyframes AUy_zG_pulse {
  50% {
    opacity: 0;
  }
}

.AUy_zG_login {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
  display: flex;
  overflow: hidden;
}

.AUy_zG_login .AUy_zG_submitButton {
  color: #000;
  text-align: center;
  background: #fff;
  border: thin solid #000;
  border-radius: 25px;
  margin: 5px 0;
  padding: 5px 20px;
  font-size: .8em;
  position: relative;
}

.PYKqUW_error {
  color: red;
  margin: 1vh 0;
}

.PYKqUW_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.PYKqUW_contentContainer > div {
  width: 350px;
}

.PYKqUW_contentContainer .PYKqUW_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.PYKqUW_contentContainer .PYKqUW_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.PYKqUW_contentContainer .PYKqUW_pulsingText {
  animation: PYKqUW_pulse 1.5s linear infinite;
}

.PYKqUW_contentContainer .PYKqUW_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.PYKqUW_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.PYKqUW_downloadLinkDiv {
  margin: 2vh 0 0;
}

.PYKqUW_downloadLinkDiv .PYKqUW_downloadLink {
  color: #333;
}

.PYKqUW_downloadLinkDiv .PYKqUW_downloadLink:hover {
  opacity: .5;
}

@keyframes PYKqUW_pulse {
  50% {
    opacity: 0;
  }
}

.PYKqUW_scheduler {
  max-height: 45vh;
  width: 98%;
  background: #fff;
  border: thin solid #000;
  border-radius: 25px;
  margin-top: 2vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.PYKqUW_scheduler .PYKqUW_scheduleLink {
  height: 5.1vh;
  color: #000;
  border-bottom: thin solid #000;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 15px;
  text-decoration: none;
  display: flex;
}

.PYKqUW_scheduler .PYKqUW_scheduleLink:hover {
  opacity: .5;
}

.PYKqUW_scheduler :last-child {
  border-bottom: none;
}

.eijppW_error {
  color: red;
  margin: 1vh 0;
}

.eijppW_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.eijppW_contentContainer > div {
  width: 350px;
}

.eijppW_contentContainer .eijppW_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.eijppW_contentContainer .eijppW_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.eijppW_contentContainer .eijppW_pulsingText {
  animation: eijppW_pulse 1.5s linear infinite;
}

.eijppW_contentContainer .eijppW_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.eijppW_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.eijppW_downloadLinkDiv {
  margin: 2vh 0 0;
}

.eijppW_downloadLinkDiv .eijppW_downloadLink {
  color: #333;
}

.eijppW_downloadLinkDiv .eijppW_downloadLink:hover {
  opacity: .5;
}

@keyframes eijppW_pulse {
  50% {
    opacity: 0;
  }
}

.eijppW_thumbnailContainer {
  width: 350px;
  object-fit: contain;
  margin: 1vh 0;
  position: relative;
}

.eijppW_thumbnailContainer .eijppW_ARIcon {
  width: 5%;
  background-color: #fff;
  padding: 2%;
  position: absolute;
  top: 5%;
  right: 5%;
}

.eijppW_tipText {
  padding: 1vh 2vw;
  font-style: italic;
  position: relative;
}

.eijppW_thumbnail {
  width: 100%;
}

.fkTqeG_error {
  color: red;
  margin: 1vh 0;
}

.fkTqeG_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.fkTqeG_contentContainer > div {
  width: 350px;
}

.fkTqeG_contentContainer .fkTqeG_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.fkTqeG_contentContainer .fkTqeG_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.fkTqeG_contentContainer .fkTqeG_pulsingText {
  animation: fkTqeG_pulse 1.5s linear infinite;
}

.fkTqeG_contentContainer .fkTqeG_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.fkTqeG_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.fkTqeG_downloadLinkDiv {
  margin: 2vh 0 0;
}

.fkTqeG_downloadLinkDiv .fkTqeG_downloadLink {
  color: #333;
}

.fkTqeG_downloadLinkDiv .fkTqeG_downloadLink:hover {
  opacity: .5;
}

@keyframes fkTqeG_pulse {
  50% {
    opacity: 0;
  }
}

.fkTqeG_videoPlayer {
  width: 350px;
  object-fit: contain;
  margin: 1vh 0;
}

.OGmkqa_error {
  color: red;
  margin: 1vh 0;
}

.OGmkqa_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.OGmkqa_contentContainer > div {
  width: 350px;
}

.OGmkqa_contentContainer .OGmkqa_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.OGmkqa_contentContainer .OGmkqa_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.OGmkqa_contentContainer .OGmkqa_pulsingText {
  animation: OGmkqa_pulse 1.5s linear infinite;
}

.OGmkqa_contentContainer .OGmkqa_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.OGmkqa_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.OGmkqa_downloadLinkDiv {
  margin: 2vh 0 0;
}

.OGmkqa_downloadLinkDiv .OGmkqa_downloadLink {
  color: #333;
}

.OGmkqa_downloadLinkDiv .OGmkqa_downloadLink:hover {
  opacity: .5;
}

@keyframes OGmkqa_pulse {
  50% {
    opacity: 0;
  }
}

.OGmkqa_assets {
  min-width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(to right, #d6fb50 1px, #0000 1px), linear-gradient(#d6fb50 1px, #0000 1px);
  background-size: 20px 20px;
  font-family: Helvetica;
  font-size: 14px;
}

.OGmkqa_assets .OGmkqa_contentContainer .OGmkqa_assetsImage {
  width: 350px;
  object-fit: contain;
  margin: 1vh 0;
}

.luz41q_error {
  color: red;
  margin: 1vh 0;
}

.luz41q_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.luz41q_contentContainer > div {
  width: 350px;
}

.luz41q_contentContainer .luz41q_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.luz41q_contentContainer .luz41q_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.luz41q_contentContainer .luz41q_pulsingText {
  animation: luz41q_pulse 1.5s linear infinite;
}

.luz41q_contentContainer .luz41q_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.luz41q_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.luz41q_downloadLinkDiv {
  margin: 2vh 0 0;
}

.luz41q_downloadLinkDiv .luz41q_downloadLink {
  color: #333;
}

.luz41q_downloadLinkDiv .luz41q_downloadLink:hover {
  opacity: .5;
}

@keyframes luz41q_pulse {
  50% {
    opacity: 0;
  }
}

.luz41q_reserve {
  min-width: 100vw;
  min-height: 100vh;
  background-image: linear-gradient(to right, #d6fb50 1px, #0000 1px), linear-gradient(#d6fb50 1px, #0000 1px);
  background-size: 20px 20px;
  font-family: Helvetica;
  font-size: 14px;
}

.jPWDZa_error {
  color: red;
  margin: 1vh 0;
}

.jPWDZa_contentContainer {
  width: 350px;
  padding: 2vh 0;
  position: relative;
  left: calc(50vw - 175px);
  overflow: scroll;
}

.jPWDZa_contentContainer > div {
  width: 350px;
}

.jPWDZa_contentContainer .jPWDZa_smallHeaderText {
  margin: 5vh 0 0;
  font-size: 1em;
}

.jPWDZa_contentContainer .jPWDZa_largeHeaderText {
  background: linear-gradient(#d6fb50, #d6fb50) 0 20px / 100% 25px no-repeat;
  margin: 1vh 0;
  font-size: 2.33em;
}

.jPWDZa_contentContainer .jPWDZa_pulsingText {
  animation: jPWDZa_pulse 1.5s linear infinite;
}

.jPWDZa_contentContainer .jPWDZa_subText {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.jPWDZa_stretchedOvalButton {
  color: #000;
  text-align: center;
  width: 98%;
  background: none;
  border: thin solid #000;
  border-radius: 25px;
  margin: 1vh 0;
  padding: 15px 0;
  font-size: 1em;
  position: relative;
  left: 1%;
}

.jPWDZa_downloadLinkDiv {
  margin: 2vh 0 0;
}

.jPWDZa_downloadLinkDiv .jPWDZa_downloadLink {
  color: #333;
}

.jPWDZa_downloadLinkDiv .jPWDZa_downloadLink:hover {
  opacity: .5;
}

@keyframes jPWDZa_pulse {
  50% {
    opacity: 0;
  }
}

.jPWDZa_error {
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
  background-image: linear-gradient(to right, #d6fb50 1px, #0000 1px), linear-gradient(#d6fb50 1px, #0000 1px);
  background-size: 20px 20px;
  font-family: Helvetica;
  font-size: 14px;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

/*# sourceMappingURL=index.a3e1627e.css.map */
