$masterWidth: 350px;

@mixin pageStyles {
    font-size: 14px;
    font-family: 'Helvetica';
    min-width: 100vw;
    min-height: 100vh;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, #D6FB50 1px, transparent 1px),linear-gradient(to bottom, #D6FB50 1px, transparent 1px);
}

@mixin mediaContainer {
    width: 350px;
    object-fit: contain;
    margin: 1vh 0;
}

@mixin ovalButton {
    position: relative;
    background: transparent;
    border: thin black solid;
    color: black;
    border-radius: 25px;
    font-size: 1em;
    text-align: center;
}

.error {
    color: red;
    margin: 1vh 0;
}



.contentContainer {
    position: relative;
    left: calc((100vw - $masterWidth) / 2);
    width: $masterWidth;
    padding: 2vh 0;
    overflow: scroll;

    > div {
        width: $masterWidth;
    }

    .smallHeaderText {   
        margin: 5vh 0 0 0;
        font-size: 1em;      
    }

    .largeHeaderText {     
        font-size: 2.33em;
        margin: 1vh 0;
        background: linear-gradient(#D6FB50, #D6FB50);
        background-size: 100% 25px;
        background-position: 0 20px;
        background-repeat: no-repeat;
    }

    .pulsingText {
        animation: pulse 1.5s linear infinite;
    }

    .subText {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
    }
}

.stretchedOvalButton {
    @include ovalButton;
    width: 98%;
    left: 1%;
    padding: 15px 0;
    margin: 1vh 0;
}

.downloadLinkDiv {
    margin: 2vh 0 0 0;
    .downloadLink {
        color: #333333;
        &:hover {
            opacity: 0.5;
        }
    }
}

@keyframes pulse {
    50% {
      opacity: 0;
    }
  }